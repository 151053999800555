import { __awaiter, __generator } from "tslib";
import { app as api } from '@ekuaibao/whispered';
import { getSpecificationByType, getPaymentPlans, getDeductPlan, getContractEntityType, getLedgerType } from './actions';
export default [
    {
        id: '@supplier-payment-management',
        exact: true,
        dependencies: ['@auth-check'],
        'get:specificion:contract': function (params) {
            return getSpecificationByType(params);
        },
        'create:payment:plan': function (entity) { return __awaiter(void 0, void 0, void 0, function () {
            var legerDataLinkType, contractId, data, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        legerDataLinkType = entity.legerDataLinkType;
                        contractId = (_b = entity === null || entity === void 0 ? void 0 : entity.dataLink) === null || _b === void 0 ? void 0 : _b.id;
                        if (!(legerDataLinkType !== 'AMORTIZE_PLAN')) return [3 /*break*/, 2];
                        return [4 /*yield*/, getPaymentPlans({ contractId: contractId })];
                    case 1:
                        _a = _c.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, getDeductPlan({ contractId: contractId })];
                    case 3:
                        _a = _c.sent();
                        _c.label = 4;
                    case 4:
                        data = _a;
                        return [4 /*yield*/, api.open('@supplier-payment-management:PaymentPlanModal', { entityInfo: { data: entity, planType: legerDataLinkType !== 'AMORTIZE_PLAN' ? 'createPaymentPlan' : 'createArtiPlan' }, value: data === null || data === void 0 ? void 0 : data.items })];
                    case 5:
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        'get:contract:entity:byType': function (entityId) {
            return getContractEntityType(entityId);
        },
        'getLedger': function (ledgerId) {
            return getLedgerType(ledgerId);
        },
    },
    {
        point: '@@layers',
        prefix: '@supplier-payment-management',
        onload: function () { return import('./layers'); }
    },
    {
        path: '/business-settings',
        ref: '/',
        onload: function () { return import('./setting/SettingManager'); }
    },
    {
        path: '/contract-manage',
        ref: '/',
        onload: function () { return import('./manager-view'); }
    },
    {
        path: '/payment-plan-query',
        ref: '/',
        onload: function () { return import('./manager-view'); }
    },
    {
        path: '/amortize-plan-query',
        ref: '/',
        onload: function () { return import('./manager-view'); }
    },
];
