/**
 * Created by Yun.kou on 17/08/17.
 */
import { app as api } from '@ekuaibao/whispered'
import { Fetch, Resource } from '@ekuaibao/fetch'
import { QuerySelect } from 'ekbc-query-builder'
const dataLinkV1 = new Resource('/api/v1/datalink')
const dataLink = new Resource('/api/v2/datalink')
const dataLinkHomeVisible = new Resource('/api/v1/organization')
const rules = new Resource('/api/v1/mapping')
const ledgerAction = new Resource('/api/v2/datalink/ledger')
const propertySetAction = new Resource('/api/flow/v2/propertySet')
const specification = new Resource('/api/form/v2')
const dataLinkElem = new Resource('/api/tpp/v2/elem/dataLink/dataLinkToElem')
const tpp = new Resource('/api/tpp/v2')
const dimensions = new Resource('/api/v1/basedata/dimensionItems')
const departments = new Resource('/api/v1/organization/departments')
const supplierV3 = new Resource('/api/supplier/v3')
const specificationV3 = new Resource('/api/form/v2/specificationGroups')
const propertySet = new Resource('/api/flow/v2/propertySet')
const orderCenter = new Resource('/api/tpp/v2/order/center')
const tppOrder = new Resource('/api/tpp/v2/order/center')
const enums = new Resource('/api/v1/basedata/enumItems/byEnumCode')
export function savePlatform(params) {
  return !!params.id ? dataLink.PUT('/platform/$id', params) : dataLink.POST('/platform', params)
}

export function savePaymentPlans(params) {
  return supplierV3.POST('/paymentPlan/save', params)
}
export function saveDeductPlan(params) {
  const { contractId } = params
  return supplierV3.POST('/deductPlan/save', params, { contractId })
}
export function getDeductPlan(params) {
  return supplierV3.POST('/deductPlan/query', {}, params)
}

export function getPaymentPlans(params) {
  return supplierV3.POST('/paymentPlan/query', {}, params)
}

export function getContractEntityType(entityId) {
  return supplierV3.GET('/corppayment/getContractPlanMapping', { entityId })
}

export function getSpecificationByType(params) {
  let specificationJoin = {
    join: `specifications.components.assignmentRule,assignmentRule,/v1/mapping/fieldMapping`,
    join$1: `specifications.components.referenceData,referenceData,/v2/datalink/entity?join=platformId,platformId,/v2/datalink/platform`,
  }
  return specificationV3.GET('/getCorpPaymentSpecification', { ...params, ...specificationJoin })
}

export function getModuleList() {
  return supplierV3.POST('/moduleManage/query')
}

export function saveModuleList(params) {
  return supplierV3.POST('/moduleManage/save', params)
}
export function enableModule(id) {
  return supplierV3.PUT('/corppayment/enable/$id', { id })
}

export function saveTaxRule(params) {
  return supplierV3.POST('/deductRule/save', params)
}

export function getTaxRule() {
  return supplierV3.POST('/deductRule/query')
}

export function enableTaxRule(params) {
  return supplierV3.PUT('/deductRule/enable', {}, params)
}

export function getEnumsList(code) {
  return enums.GET('/$code', { code })
}

export function getLedgerType(ledgerId) {
  return supplierV3.GET('/corppayment/getLedgerType', { ledgerId })
}

export function getLedgerList(entityId) {
  return ledgerAction.GET('/byEntityId/$entityId', { entityId })
}

// 获取付款 摊销状态
export function getPaymentAmortizeStatus(contractId) {
  return supplierV3.POST('/contract/query', {}, contractId)
}
